import '@draft-js-plugins/mention/lib/plugin.css'
import { useJsApiLoader } from '@react-google-maps/api'
import * as Sentry from '@sentry/react'
import { PostHogProvider } from 'posthog-js/react'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, posthog, useAuth } from './auth'
import './index.css'
import './scaffold.css'
import './style/semantic-ui-css/semantic.min.css'

Sentry.init({
  dsn: 'https://50e8504744f849eea42b34b10927eb11@o4505024865173504.ingest.sentry.io/4505024880771072',
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      // This maximizes replay effectiveness but at the expense of privacy.
      // Consider removing these flags once the system is more stable.
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ['/.netlify/functions/graphql'],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // 1.0 == Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event, hint) => {
    // Ignore errors that are due to user input or otherwise encountered in the regular course of business
    if (
      [
        'BAD_USER_INPUT',
        'GRAPHQL_VALIDATION_FAILED',
        'UNAUTHENTICATED',
      ].includes(hint.originalException?.graphQLErrors?.[0]?.extensions?.code)
    ) {
      return null // if return null, Sentry won't capture the event
    }
    return event // Sentry will capture the event
  },
})

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[]

const libraries: Libraries = ['places']

const App = () => {
  const { isLoaded: _isLoaded, loadError: _loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyC3CqzdKg1-NLg37v1VMff9oJoDQFu_CiM',
    libraries,
  })
  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider useAuth={useAuth}>
            <PostHogProvider client={posthog}>
              <Routes />
            </PostHogProvider>
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  )
}

export default App
